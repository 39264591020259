let ROOT_URL = "";
let IMAGE_URL = "";
let WEBURL = ""

if (process.env.REACT_APP_SERVER?.trim() == "production") {
  ROOT_URL = "https://api.samskara.app/api/v1/c";
  IMAGE_URL = "https://d2yiyjum1sxmcg.cloudfront.net/";
} else {
  ROOT_URL = "https://api.samskara.app/api/v1/c";

  // ROOT_URL = "https://samskara-api.appdevelop.in/api/v1/c";
  // ROOT_URL = "http://staging.alphonic.net.in:6510/api/v1/c";
  // ROOT_URL = "http://192.168.1.2:6510/api/v1/c";

  WEBURL = "https://samskara.app"

  // ROOT_URL = "http://localhost:6510/api/v1/c";
  IMAGE_URL = "https://d2o16grlb19pkv.cloudfront.net/";
}
export { IMAGE_URL, WEBURL };
export default ROOT_URL;   
