import { toast } from "react-toastify";
import { reactLocalStorage } from "reactjs-localstorage";
import * as Yup from 'yup';
import ROOT_URL from "./baseURL";
import authAxios from "./authAxios";

const common = {
  loader: (type) => {
    if (type) {
      document.body.className = 'loading_page';
    } else {
      document.body.className = document.body.className.replace("loading_page", "");
    }
  },
  base64Mime: (encoded) => {
    var result = null;
    if (typeof encoded !== 'string') {
      return result;
    }
    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mime && mime.length) {
      result = mime[1];
    }
    return result;
  },
  base64ReadFile: (base64String) => {
    var nonBlob = base64String == undefined ? 0 : base64String.length;
    var filetype = common.base64Mime(base64String)
    var datass = { size: nonBlob / 1000, type: filetype }
    return datass;
  },
  bufferToBase64: (arr) => {
    //arr = new Uint8Array(arr) if it's an ArrayBuffer
    let buffer = btoa(
      arr.reduce((data, byte) => data + String.fromCharCode(byte), '')
    )
    return 'data:image/png;base64,' + buffer;
  },
  previewURL: (file) => {
    let URL = '';
    if (file != '') {
      URL = window.URL.createObjectURL(file)
    }
    return URL;
  },
  mineTypeValidate: (value) => {
    if (value == undefined || value == null) {
      return false;
    }
    let fileType = value.type;
    return value && (
      fileType === "image/jpeg" ||
      fileType === "image/bmp" ||
      fileType === "image/png"/* ||
      fileType === 'application/pdf' ||
      fileType === "application/msword" */
    )
  },
  fileSizeValidate: (value, size) => {
    if (value == undefined || value == null) {
      return false;
    }
    let fileSize = value.size
    if (!fileSize) {
      fileSize = 2
    }
    let mb = fileSize * 1024;
    return fileSize <= mb
  },
  getMiles: (i) => {
    return (i * 0.000621371192).toFixed(1);
  },
  coupanTypeDiscount: (obj) => {
    let ctype = obj.couponType || "";
    let price = obj.price || 0;
    let discount = obj.amount || 0;
    let minAmount = obj.minAmount || 0;
    let disUpto = obj.disUpto || 0;
    let disRate = 0;
    console.log(obj);
    if (obj.referralType == "REFERRAL") {
      return parseFloat(discount.toFixed(2));
    }
    if (ctype == "FLAT PERCENT") {
      disRate = price >= minAmount ? (price * discount) / 100 : disRate;
    } else if (ctype == "FLAT PERCENT UPTO") {
      disRate = price >= minAmount ? (price * discount) / 100 : disRate;
      disRate = disRate <= disUpto ? disRate : disUpto;

    } else if (ctype == "CASH DISCOUNT UPTO") {
      disRate = price >= minAmount ? discount : disRate;
      // disRate = disRate <= disUpto ? disRate : disUpto;
    }
    console.log(disRate);
    return parseFloat(disRate.toFixed(2));
  },
  isMobile: (num) => {
    var isphone = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(num);
    return isphone;
  },
  coordinateLocal: async () => {
    let coordataL;
    function asignData(data) {
      coordataL = data
    }
    await navigator.geolocation.getCurrentPosition(function (position) {
      let lat = position.coords.latitude;
      let lng = position.coords.longitude;
      let jsonCoords = {
        lat: lat,
        lng: lng
      }
      jsonCoords = JSON.stringify(jsonCoords);
      asignData(jsonCoords)
      localStorage.setItem('geoLocal', jsonCoords);
      // reactLocalStorage.set('geoLocal', jsonCoords);
    })
    return coordataL;
  },
  creditCardType: (cardType) => {
    let imageUrl;
    cardType = cardType.toLowerCase();
    switch (cardType) {
      case "visa":
        imageUrl = "card-logo-visa.svg";
        break;
      case "mastercard":
        imageUrl = "card-logo-mastercard.svg";
        break;
      case "american-express":
        imageUrl = "card-logo-amex.svg";
        break;
      default:
        imageUrl = "card-logo-unknown.svg";
    }
    return imageUrl;
  },
  imageValidate: (extention, size, msg) => {
    extention = extention || ['image/jpg', 'image/jpeg', 'image/png']
    msg = msg || "You need to attach image";
    size = size || 1024 * 1024
    let image = Yup
      .mixed()
      .required(msg)
      .test("fileSize", "The image is too large", (value) => {
        if (value == undefined || value == null) {
          return false;
        }
        return value && value?.size <= size;
      }).test("type", `Only the following formats are accepted: ${extention}`, (value) => {
        if (value == undefined || value == null) {
          return false;
        }
        let fileType = value.type;
        return value && (extention.indexOf(fileType) != -1);
      })
    return image;
  },
  uploadImage: async (values) => {
    let data;
    common.loader(true);
    const formData = new FormData();
    formData.append("coverImage", values.userImage);
    data = await authAxios({
      method: 'POST',
      url: `/media/upload`,
      data: formData
    }).then(res => {
      return res.data.data._id
    }).catch((error) => {
      common.error(error);
    })
    common.loader(false);
    return data;
  },
  error: (error) => {
    var message = JSON.parse(error.request.response).message;
    toast.error(message);
    return message;
  },
  errorMsg: (message) => {
    toast.error(message);
    return message;
  },

  trim: (d) => {
    if (d == undefined || d == null || d == '') {
      return
    }
    return d.replace(/(<([^>]+)>)/ig, '')
  },
  timeFormate: (m) => {
    let totalMinutes = m || 0;
    let hours = Math.floor(totalMinutes / 60);
    hours = hours > 9 ? hours : '0' + hours
    let minutes = totalMinutes % 60;
    minutes = minutes > 9 ? minutes : '0' + minutes
    return hours + ": " + minutes
  },
  percentage: (b, a) => {
    let price = a || 0
    let disPrice = b || 0
    let percentage = Math.round(100 - (disPrice * 100) / price)
    return percentage + '%'
  },
  version: () => {
    let date = new Date();
    let day = date.getDate() <= 15 ? 'ER' : 'LT'
    const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let monthName = (month[date.getMonth()]).toUpperCase();
    let getFullYear = ((date.getFullYear()).toString()).substr(2);

    let version = day + '-' + monthName + '-' + getFullYear
    return version

  },
  getImage: async (id) => {
    if (id) {
      let data;
      data = await authAxios({
        method: 'POST',
        url: `/imageurl`,
      }).then((res) => {
        return common.bufferToBase64(res.data.data.Body.data);
      })
        .catch((error) => {
          common.error(error);
        });
      return data;
    }
  },
  getImagePublic: async (id) => {
    if (id) {
      let data;
      data = await authAxios({
        method: "GET",
        url: `${ROOT_URL}/download/fileurl/${id}`,
        loader: true,
      }).then((res) => {
        return res.data.data;
      })
        .catch((error) => {
          common.error(error);
        });
      return data;
    }
  },
  commentLike: (commentId) => {
    common.loader(true);
    let res = authAxios({
      method: "GET",
      url: `${ROOT_URL}/comment/likeComment?commentId=${commentId}`,
      //   data: postData,
    }).then((res) => {
      return res
      // toast.success(res.data.message);
    }).catch((error) => {
      common.error(error);
    });
    common.loader(false);
    return res
  },
  commentDisLike: (commentId) => {
    common.loader(true);
    let res = authAxios({
      method: "GET",
      url: `${ROOT_URL}/comment/disLikeComment?commentId=${commentId}`,
      //   data: postData,
    }).then((res) => {
      return res
      // toast.success(res.data.message);
    }).catch((error) => {
      common.error(error);
    });
    common.loader(false);
    return res
  },
  addToCart: (postData) => {
    common.loader(true);
    let res = authAxios({
      method: "POST",
      url: `${ROOT_URL}/cart/add`,
      data: postData,
    }).then((res) => {
      return res
      // toast.success(res.data.message);
    }).catch((error) => {
      common.error(error);
    });
    common.loader(false);
  },
  padTo2Digits: (num) => {
    return num.toString().padStart(2, '0');
  },
  minuteToHour: (m) => {
    let minutes = m % 60;
    let hours = Math.floor(m / 60);
    return `${common.padTo2Digits(hours)}:${common.padTo2Digits(minutes)}`
  },
  addDay: (date, day) => {
    return date.setDate(new Date().getDate() + day)
  },
  minBookingDate: () => {
    let minDay = common.addDay(new Date(), 2);
    if(new Date().getHours() < 17){
      minDay = common.addDay(new Date(), 1);
    }
    return minDay;
  },
  discountAmount: (amount, percentage) => {
    if(percentage === ''){
      percentage = 10;
    }
    if(amount <= 1) return 0;
    let discountAmount = (amount * percentage) / 100
    return discountAmount || 0
  },
  limitDiscount: (discountAmount, total) => {
    if(total - discountAmount <= 0){
      return Math.floor(total);
    }else if(total - discountAmount < 1){
      return discountAmount - (1 - (total - discountAmount));
    }else{
      return discountAmount;
    }
  },
  priceUp: (amount, percentage) =>{
    const upAmmount = amount/(1 - (percentage/100))
    return (Math.round(upAmmount / 10) * 10).toFixed(2)||0;
  },
  downloadInvoce: async (id) => {
    try {
      const downloadLink = await authAxios({
        method: "GET",
        url: `order/getInvoice/${id}`,
      })
      window.open(`${downloadLink.data.data}`, "_blank", "toolbar=false,scrollbars=yes,resizable=yes,fullscreen=yes");
      return downloadLink.data.data
    } catch (error) {
      console.log(error)
    }
  },
  matchDate: (date) => {
    let cDate = new Date();
    cDate.setHours(0, 0, 0, 0)
    let cTime = cDate.getTime();
    let fDate = new Date(date)
    let d = fDate.getDate()
    let m = fDate.getMonth()
    let y = fDate.getFullYear()
    let fNewDate = new Date(y, m, d, 0, 0, 0, 0)
    let fnewTime = fNewDate.getTime()
    return cTime === fnewTime
  },
  packagePrice: (eventData) => {

    let TPrice = 0
    let AddonPriceDefault = eventData?.packageId?.defaultAddons?.map((d) => {

      TPrice += Number(d.price) * Number(d.quantity) * Number(d.priceMlt)
      return d
    }) || 0
    let AddonPriceCustom = eventData?.customAddons?.map((d) => {

      TPrice += Number(d.price) * Number(d.quantity) * Number(d.priceMlt)
      return d
    }) || 0


    return TPrice
  },
  subtoalPackagePrice: (eventData, price) => {

    let TPrice = price || 0
    let AddonPriceDefault = eventData?.packageId?.defaultAddons?.map((d) => {
      TPrice += d.price * d.quantity
      return d
    }) || 0
    let AddonPriceCustom = eventData?.customAddons?.map((d) => {
      TPrice += d.price * d.quantity
      return d
    }) || 0

    return TPrice
  },
  subtractHours: (date, hours) => {

    date.setHours(date.getHours() - hours);
    return date;
  },



  capitalizeWord: (str) => {
    let splitStr = str?.toLowerCase()?.split(" ")
    for (let i = 0; i < splitStr?.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    return splitStr?.join(" ")
  },

  stagingdefaultSelectValue: () => {
    return {
      country: {
        _id: "62fcb35b56b3aa6321a9e751",
        status: true,
        name: "india",
        currency: "INR",
        createdAt: "2022-08-17T09:22:35.562Z",
        updatedAt: "2022-08-17T09:22:35.562Z",
        __v: 0,
      },
      city: {
        _id: "62f22aad583696ebc0aed34f",
        image: {
          _id: "62f22aac583696ebc0aed34c",
          name: "Delhi.png",
          size: "14 KB",
          type: "image/png",
          encoding: "7bit",
          path: "https://alp-staging-bucket.s3.ap-south-1.amazonaws.com/samskara/rSOnX_Delhi.png",
          createdAt: "2022-08-09T09:36:44.828Z",
          updatedAt: "2022-08-09T09:36:44.828Z",
          __v: 0,
        },
        status: true,
        parentStatus: true,
        name: "Delhi",
        countryId: "62fcb35b56b3aa6321a9e751",
        createdAt: "2022-08-09T09:36:45.558Z",
        updatedAt: "2022-09-27T05:45:00.825Z",
        __v: 0,
      },
      preference: {
        image: "303030303030303030303030",
        _id: "62721689dc93698793ed4106",
        status: true,
        name: "Hindi",
        createdAt: "2022-05-04T06:00:41.826Z",
        updatedAt: "2022-08-10T07:18:09.394Z",
        __v: 0,
        nativeName: "हिन्दी",
      }
    }
  },
  productionDefaultValue: () => {
    return {
      country: {
        _id: "626655306c4b4252b01abbe5",
        status: true,
        name: "INDIA",
        createdAt: "2022-04-25T08:00:48.505Z",
        updatedAt: "2022-09-01T11:46:51.515Z",
        __v: 0,
        currency: "INR"
      },
      city: {
        _id: "6303cb4510d0bec76494f098",
        image: {
          _id: "63664a4bc1da058f2de01a0f",
          name: "delhi logo new.png",
          size: "377 KB",
          type: "image/png",
          encoding: "7bit",
          path: "https://d2yiyjum1sxmcg.cloudfront.net/samskara/4WBL8_delhi%20logo%20new.png",
          createdAt: "2022-11-05T11:34:35.244Z",
          updatedAt: "2022-11-05T11:34:35.244Z",
          __v: 0
        },
        status: true,
        parentStatus: true,
        name: "Delhi",
        countryId: "626655306c4b4252b01abbe5",
        createdAt: "2022-08-22T18:30:29.228Z",
        updatedAt: "2023-02-06T06:56:28.146Z",
        __v: 0
      },
      preference: {
        image: "303030303030303030303030",
        _id: "630745cb7f005678076b03e6",
        status: true,
        name: "Hindi",
        nativeName: " हिन्दी",
        createdAt: "2022-08-25T09:50:03.325Z",
        updatedAt: "2022-08-26T07:37:22.844Z",
        __v: 0
      }
    }
  },

  validateDate: (inputDate) => {
    let validate = false

    if (new Date().getFullYear() < new Date(inputDate).getFullYear()) {
      validate = true
    }
    else if (new Date().getFullYear() == new Date(inputDate).getFullYear()) {
      if (new Date().getMonth() < new Date(inputDate).getMonth()) {
        validate = true
      }
      else if (new Date().getMonth() == new Date(inputDate).getMonth()) {
        if (new Date(new Date().setDate(new Date().getDate() + 1)).getDate() < new Date(inputDate).getDate()) {
          validate = true
        }else if(new Date(new Date().setDate(new Date().getDate() + 1)).getDate() == new Date(inputDate).getDate() && new Date().getHours()<17){
          validate = true
        }
      }
    }



    return validate
  }

}

export default common